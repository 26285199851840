<template>
	<div>
    <div class="title" v-html="title"></div>
    <div class="xieyi" v-html="description"></div>
    <div class="common_block2">
      <van-button type="warning" block @click="agree">同意协议</van-button>
    </div>
	</div>
</template>

<script>
	export default {
		name: 'personal_index',
		data() {
			return {
        title:'',
				description:'',
        step:''
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
      this.getProtocol()
      // this.term()
		},
		methods: {
      // term(){
      //   this.$axios({
      //     method: 'post',
      //     url: 'info_term/speedpay', 
      //   }).then ((res) => {
      //     if (res.success) {
            
      //       console.log(res)
      //       this.description=res.data.description||'';
      //     }else {
      //       this.$dialog.alert({
      //         message: res.error_text
      //       })
            
      //     }
      //   })
      // },
      agree(){
        this.$axios({
          method: 'post',
          url: 'speedpay_user/checkTerm', 
          data: {
            token: this.$route.query.token,
            checked:1
          }
        }).then ((res) => {
          if (res.success) {
            this.$router.replace('/speedpay/papers?token='+this.$route.query.token)
          }else {
            this.$dialog.alert({
              message: res.error_text
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 2000);
          }
        })
      },
      getProtocol(){
        this.$axios({
					method: 'post',
					url: 'speedpay_user/index', 
					data: {
            token: this.$route.query.token,
          }
				}).then ((res) => {
					if (res.success) {
            this.step=res.data.step
            if(this.step==1){
              this.title=this.HTMLDecode(res.data.data.name)||'';
              this.description=this.HTMLDecode(res.data.data.description)||'';
            }else if(this.step==2){
              this.$router.replace('/speedpay/papers?token='+this.$route.query.token)
            }else if(this.step==3){
              this.$router.replace('/speedpay/papers?token='+this.$route.query.token)
            }else if(this.step==4){
              this.$router.replace('/speedpay/papers?token='+this.$route.query.token)
            }
            else if(this.step==5){
              this.$router.replace('/speedpay/step5?token='+this.$route.query.token)
            }
            else if(this.step==6){
              this.$router.replace('/speedpay/step6?token='+this.$route.query.token)
            }
            else if(this.step==7){
              this.$router.replace('/speedpay/step7?token='+this.$route.query.token)
            }
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      HTMLDecode(text) { 
        var temp = document.createElement("div"); 
        temp.innerHTML = text; 
        var output = temp.innerText || temp.textContent; 
        temp = null; 
        return output; 
      }
     
		},
	}
</script>
<style scoped>
body{
  background: #fff;
}
  .title{
    font-size: 16px;
    font-weight: bold;
    padding: 30px 0;
    text-align: center;
    background: white;
  }
  .xieyi{
    background: white;
    padding: 0 30px 30px;
    font-size: 14px;
    line-height: 25px;
    /* color: #666; */
    padding-bottom: 60px;
    letter-spacing: 1px;
    font-family: '微软雅黑';
    font-weight: 400;
    font-style: normal;
  }
  .common_block2{
    position: fixed;
    padding: 15px 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
  }
</style>
